// import { useEffect, useState } from "react";

// import { gql } from "@apollo/client";
// import node module libraries
import { Card, Form, Button } from "react-bootstrap";

// import custom components
// import { FormSelect } from "components/elements/form-select/FormSelect";
// import ReactQuillEditor from "components/elements/editor/ReactQuillEditor";
import GKTagsInput from "components/elements/tags/GKTagsInput";

// date-range css
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css";
// import { DateRange } from "react-date-range";

// import { formatDate } from "helper/utils";
import { LANGUAGES } from "utils/UtilData";
// import moment from "moment";

// const GET_ALL_PACKAGES = gql`
//   query AllPackages {
//     allPackages {
//       edges {
//         node {
//           guid
//           name
//           price
//         }
//       }
//     }
//   }
// `;

const BasicInformation = (props) => {
  const { next, handleChange, data } = props;
  // const [dateRange, setDateRange] = useState({
  //   startDate: new Date(),
  //   endDate: new Date() ,
  //   key: "selection",
  // });

  // const [showPicker, setShow] = useState(false);
  console.log('data', data)

  const CoursesLevel = [
    { value: "Easy", label: "Easy" },
    { value: "Medium", label: "Medium" },
    { value: "Hard", label: "Hard" },
  ];

  const TAGS = [
    { value: "Bootcamp" },
    { value: "Master Class" },
    { value: "For Parents" },
    { value: "For Healthcare Workers" },
  ];

  // console.log('edit_course', data);

  // useEffect(()=>{
  //   if(data){
  //     setDateRange({
  //       startDate: data?.courseStartDate,
  //       endDate: data?.courseEndDate,
  //       key: "selection",
  //     });
  //   }

  // },[data])

  return (
    <Form>
      {/* Card */}
      <Card className="mb-3 ">
        <Card.Header className="border-bottom px-4 py-3">
          <h4 className="mb-0">Basic Information</h4>
        </Card.Header>
        {/* Card body */}
        <Card.Body>
          {/* Title  */}
          <Form.Group className="mb-3">
            <Form.Label htmlFor="courseTitle">Course Title</Form.Label>
            <Form.Control
              type="text"
              placeholder="Course Title"
              value={data?.title}
              id="title"
              name="title"
              onChange={handleChange}
            />
            <Form.Text className="text-muted">
              Write a 60 character course title.
            </Form.Text>
          </Form.Group>

          {/* Courses level */}
          <Form.Group className="mb-3">
            <Form.Label>Courses level</Form.Label>
            <Form.Select
              // options={CoursesLevel}
              id="dificultyLevel"
              name="dificultyLevel"
              value={data?.dificultyLevel}
              // placeholder="Select level"
              onChange={handleChange}
            >
              <option >Select Level</option>
              {CoursesLevel.map((item, index) => (
                <option key={index} value={item.value}>{item.label}</option>
              ))}
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Courses Tag</Form.Label>
            <Form.Select
              id="tags"
              name="tags"
              value={data?.tags?.length !== 0 ? data.tags[0] : null}
              onChange={handleChange}
            >
              <option >Select Tag</option>
              {TAGS.map((item, index) => (
                <option key={index} value={item.value}>{item.value}</option>
              ))}
            </Form.Select>
          </Form.Group>


          <Form.Group className="mb-3">
            <Form.Label htmlFor="courseTitle">Course Language</Form.Label>
            <Form.Select
              id="language"
              name="language"
              value={data?.language}
              required
              onChange={(e) => {
                handleChange(e)
              }}
            >
              <option >Select Language</option>
              {LANGUAGES.map((item, index) => (
                <option key={index} value={item.name}>{item.name}</option>
              ))}
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Course Description</Form.Label>
            {/* <ReactQuillEditor
							initialValue={initialValue}
							id="description"
							name="description"
							onChange={handleQuillChange}
						/> */}
            <Form.Control
              as="textarea"
              type="text"
              placeholder="Course Description"
              defaultValue={data?.description}
              id="description"
              name="description"
              onChange={handleChange}
            />
            <Form.Text className="text-muted">
              A brief summary of your courses.
            </Form.Text>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label htmlFor="courseTitle">Course Objectives</Form.Label>
            <GKTagsInput
              defaulttags={data?.learnObjectives}
              onChange={(data) => {
                props.handleChange({
                  target: {
                    name: "learnObjectives",
                    value: data,
                  },
                });
              }}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label htmlFor="courseTitle">Course Requirements</Form.Label>
            <GKTagsInput
              defaulttags={data?.requirements}
              onChange={(data) => {
                props.handleChange({
                  target: {
                    name: "requirements",
                    value: data,
                  },
                });
              }}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label htmlFor="courseTitle">Course Price</Form.Label>
            <Form.Control
              type="number"
              placeholder="Course Price"
              defaultValue={data?.price}
              id="price"
              name="price"
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label htmlFor="courseTitle">Course Start Date</Form.Label>
            <Form.Control
              type="datetime-local"
              // placeholder="Course Price"
              defaultValue={data?.courseStartDate}
              id="courseStartDate"
              name="courseStartDate"
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label htmlFor="courseEndDate">Course End Date</Form.Label>
            <Form.Control
              type="datetime-local"
              // placeholder="Course Price"
              defaultValue={data?.courseEndDate}
              id="courseEndDate"
              name="courseEndDate"
              onChange={handleChange}
            />
          </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label htmlFor="virtualLink">Create Course Virtual Link</Form.Label>
              {/* <Form.Control
                type="text"
                placeholder="Course Virtual Link"
                // defaultValue={data?.virtualLink}
                id="virtualLink"
                name="virtualLink"
                onChange={handleChange}
              /> */}
              <Form.Check
                id="virtualLink"
                name="virtualLink"
                checked={data?.virtualLink === "True"}
                label="Create Course Virtual Link"
                onChange={handleChange}
              />
            </Form.Group>

          {/* <Form.Group className="mb-3">
            <Form.Label htmlFor="courseEstimateTime">Course Duration</Form.Label>
            <Form.Control
              type="text"
              placeholder="Pick dates"
              id="courseEstimateTime"
              name=""
              value={`${formatDate(dateRange.startDate)} - ${formatDate(dateRange.endDate)}`}
              onClick={() => setShow(true)}
              // onChange={handleChange}
            />

            {showPicker && (
              <div>
                <Date
                  ranges={[dateRange]}
                  onChange={(ranges) => {
                    setDateRange(ranges.selection);
                    props.handleChange({
                      target: {
                        name: "courseEndDate",
                        value: moment(ranges.selection.endDate).format('YYYY-MM-DD, HH:mm'),
                      },
                    });
                    props.handleChange({
                      target: {
                        name: "courseStartDate",
                        value:  moment(ranges.selection.startDate).format('YYYY-MM-DD, HH:mm'),
                      },
                    });
                  }}
                />
                <Button size="sm" onClick={() => setShow(false)}>
                  Close
                </Button>
              </div>
            )}
          </Form.Group> */}

        </Card.Body>
      </Card>
      {/* Button */}
      <Button variant="primary" onClick={next}>
        Next
      </Button>
    </Form>
  );
};
export default BasicInformation;
