// ----------------------------------------------------------------------

function path(root, sublink) {
    return `${root}${sublink}`;
}


const ROOTS_AUTH = '/authentication';
const ROOTS_ACADEMY = '/academy';
const ROOTS_ADMIN = '/';

const ROOTS_USER = '/user';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
    root: ROOTS_AUTH,
    login: path(ROOTS_AUTH, '/sign-in'),
    signup: path(ROOTS_AUTH, '/sign-up'),
    forgotPassword: path(ROOTS_AUTH, '/forget-password'),
    resetPassword: path(ROOTS_AUTH, '/reset-password'),
}

export const PATH_DASHBOARD = {
    root: ROOTS_ACADEMY,
    courses: {
        category: path(ROOTS_ACADEMY, '/course-category/'),
        browse: path(ROOTS_ACADEMY, '/courses/course-filter-page/'),
        checkout: (guid) => path(ROOTS_ACADEMY, `/courses/course-checkout/${guid}/`),
        view: (guid) => path(ROOTS_ACADEMY, `/courses/course-view/${guid}/`),
        resume: (guid) => path(ROOTS_ACADEMY, `/courses/course-resume/${guid}/`),

    },
    student: {
        root: path(ROOTS_ACADEMY, '/student/dashboard/'),
        subscriptions: path(ROOTS_ACADEMY, '/student/student-subscriptions/'),
        billing: path(ROOTS_ACADEMY, '/student/student-billing-info/'),
        payment: path(ROOTS_ACADEMY, '/student/student-payment/'),
        invoice: path(ROOTS_ACADEMY, '/student/student-invoice/'),
        quiz: path(ROOTS_ACADEMY, '/student/student/quiz/'),

        profile: path(ROOTS_ACADEMY, '/student/student-edit-profile/'),
        security: path(ROOTS_ACADEMY, '/student/student-security/'),
        socialProfile: path(ROOTS_ACADEMY, '/student/student-social-profiles/'),
        notifications: path(ROOTS_ACADEMY, '/student/student-notifications/'),
        profilePrivacy: path(ROOTS_ACADEMY, '/student/student-profile-privacy/'),
        deleteProfile: path(ROOTS_ACADEMY, '/student/student-delete-profile/'),
        linkedAccounts: path(ROOTS_ACADEMY, '/student/student-linked-accounts/'),

    },
    healthworker: {
        patients: path(ROOTS_ACADEMY, '/healthworker/patients/'),
        patientsCreate: path(ROOTS_ACADEMY, `/healthworker/patients/add/`),
        patientsEdit: (guid) => path(ROOTS_ACADEMY, `/healthworker/patients/edit/${guid}`),
        patientForms: (guid) => path(ROOTS_ACADEMY, `/healthworker/patients/forms/${guid}`),
        patientFormAdd: (guid) => path(ROOTS_ACADEMY, `/healthworker/patients/forms/${guid}/add`),
        // patients: path(ROOTS_ACADEMY, '/healthworker/patients/'),

    },
    instructor: {
        root: path(ROOTS_ACADEMY, '/instructor/instructor-my-courses/'),
        dashboard: path(ROOTS_ACADEMY, '/instructor/dashboard/'),
        addNewCourse: path(ROOTS_ACADEMY, '/instructor/add-new-course/'),
        edit: (guid) => path(ROOTS_ACADEMY, `/instructor/edit-course/${guid}/`),
        editDetails: (guid) => path(ROOTS_ACADEMY, `/instructor/edit-course-details/${guid}/`),

        courses: path(ROOTS_ACADEMY, '/instructor/instructor-my-courses/'),
        reviews: path(ROOTS_ACADEMY, '/instructor/instructor-reviews/'),
        earnings: path(ROOTS_ACADEMY, '/instructor/instructor-earnings/'),
        orders: path(ROOTS_ACADEMY, '/instructor/instructor-orders/'),
        students: path(ROOTS_ACADEMY, '/instructor/instructor-students/'),

        profile: path(ROOTS_ACADEMY, '/instructor/instructor-edit-profile/'),
        security: path(ROOTS_ACADEMY, '/instructor/instructor-security/'),
        socialProfile: path(ROOTS_ACADEMY, '/instructor/instructor-social-profiles/'),
        notifications: path(ROOTS_ACADEMY, '/instructor/instructor-notifications/'),
        profilePrivacy: path(ROOTS_ACADEMY, '/instructor/instructor-profile-privacy/'),
        deleteProfile: path(ROOTS_ACADEMY, '/instructor/instructor-delete-profile/'),
        linkedAccounts: path(ROOTS_ACADEMY, '/instructor/instructor-linked-accounts/'),
    },
    meeting: {
        join: (guid) => path(ROOTS_ACADEMY, `/courses/course-meeting/${guid}/`),
    },
};

export const PATH_ADMIN = {
    root: ROOTS_ADMIN,
    admin: {
        root: path(ROOTS_USER, ''),
        users: path(ROOTS_USER, '/users'),
        instructors: path(ROOTS_USER, '/instructor'),
        addInstructors: path(ROOTS_USER, '/instructor/add-new'),
        addUsers: path(ROOTS_USER, '/user/add-new'),
        editUser: (guid) => path(ROOTS_USER, `/user/edit/${guid}`),
        courses: path('', '/courses/all-courses'),
        review: (guid) => path(ROOTS_ACADEMY, `/course/review/${guid}/`),
        reviewCourse: (guid) => path(ROOTS_ACADEMY, `/courses/admin-review/${guid}/`),


    },
};


export const PATH_PAGE = {
    root: '/',

}