// import node module libraries
import React, { Fragment, useEffect, useState } from "react";
import {
  Col,
  Row,
  Container,
  Card,
  Image,
  Nav,
  Tab,
  Button,
  ProgressBar
} from "react-bootstrap";
import { useLocation, useParams } from "react-router-dom";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";

import { PaystackButton } from "react-paystack";

// import MDI icons
// import Icon from "@mdi/react";
// import { mdiAccountMultipleOutline } from "@mdi/js";

// import custom components
// import GKYouTube from "components/marketing/common/video/GKYouTube";
import Ratings from "components/marketing/common/ratings/Ratings";
import GKAccordionProgress from "components/marketing/common/accordions/GKAccordionProgress";
import LevelIcon from "components/marketing/common/miscellaneous/LevelIcon";
// import GKTippy from "components/elements/tooltips/GKTippy";

// import sub components tabs
import ReviewsTab from "components/marketing/pages/courses/course-single/ReviewsTab";
import DescriptionTab from "components/marketing/pages/courses/course-single/DescriptionTab";
import TranscriptTab from "components/marketing/pages/courses/course-single/TranscriptTab";
import FAQTab from "components/marketing/pages/courses/course-single/FAQTab";

// import data files
// import { CourseIndex } from "data/marketing/CourseIndexData";

// import media files.
// import Avatar1 from "assets/images/avatar/avatar-1.jpg";
// import { PATH_DASHBOARD } from "routes/Paths";
import moment from "moment";
import { numberWithCommas } from "helper/utils";
// import PdfViewer from "components/marketing/common/pdf-viewer/PdfViewer";
// import PDFViewerComponent from "components/marketing/common/pdf-viewer/PDFViewerComponent";
import VideoPlayer from "components/marketing/common/video/VideoPlayer";
import useAuth from "hooks/useAuth";
// import { PATH_DASHBOARD } from "routes/Paths";
import { Notify } from "notiflix";
import { Avatar } from "components/elements/bootstrap/Avatar";

// queries mutations
import { CREATE_FREE_PAYMMENT } from "helper/graphql/mutations";
import { GET_USER_PAID_PACKAGES, GET_COURSE_BY_ID, GET_USER_PAID_COURSES, GET_COURSE_PROGRESS } from "helper/graphql/queries";
import ChatTab from "../course-single/ChatTab";



// ----------------------------------------------------------------------

const CourseSingle2 = () => {
  const { guid } = useParams();


  const { pathname } = useLocation();

  const isReview = pathname.includes('review');

  // const navigate = useNavigate();

  const { isAuthenticated, user } = useAuth();

  // const user = JSON.parse(localStorage.getItem("user"));

  const TABS = !isAuthenticated ? ["Description", "Reviews"] : ["Description", "Reviews", "Chat"];

  const [course, setCourse] = useState(null);
  const [courseProgress, setCourseProgress] = useState(null);
  const [reviews, setReviews] = useState([]);
  const [modules, setModules] = useState([]);

  const [chatInfo, setChatInfo] = useState(null);
  // const [currTopic, setCurrTopic] = useState(null);

  const [isCoursePaid, setCoursePaid] = useState(false);

  const [onCreatePayment, { loading: isCreateFreeLoader }] =
    useMutation(CREATE_FREE_PAYMMENT);
  const [checkIfPaid] = useLazyQuery(GET_USER_PAID_COURSES);

  useQuery(GET_COURSE_BY_ID, {
    variables: { guid: guid, userGuid: user?.guid },
    onCompleted: (data) => {
      const _course = data?.allCourses?.edges[0]?.node;
      const _modules = _course?.coursemodulesSet?.edges?.map(
        (item) => item.node
      );
      // const _courseProgress = _course?.usercourseprogressSet?.edges?.map(item => item.node);

      setCourse(_course);
      setReviews(_course?.coursereviewsSet?.edges?.map((item) => item.node));
      // if (_courseProgress?.length !== 0) {
      //   setCourseProgress(_courseProgress);
      // }

      setModules(_modules);
      setChatInfo(_course?.courseChatGroup);
    },
  });

  useQuery(GET_COURSE_PROGRESS, {
    variables: {
      "userGuid": user?.guid,
      "courseGuid": guid
    },
    onCompleted: (data) => {
      setCourseProgress(data?.allCourseProgress?.edges[0]?.node)
    }
  });

  useEffect(() => {
    if (course != null) {
      checkIfPaid({
        variables: { userIdGuid: user?.guid, course: course?.id },
        onCompleted: (data) => {
          const _paidPacks = data?.allPackagePayments?.edges?.map(
            (item) => item.node
          );

          // console.log("paid_packs", _paidPacks)

          // if (course?.package?.isFree) {
          //   setCoursePaid(true);
          // } else {
          //   // const _package = _paidPacks.find(item => item.guid === course.package.guid);
          //   if (_paidPacks.length !== 0) {
          //     setCoursePaid(true);
          //   } else {
          //     setCoursePaid(false);
          //   }
          // }

          if (_paidPacks.length !== 0) {
            setCoursePaid(true)
          } else {
            setCoursePaid(false)
          }
        },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [course]);

  const handleTopicChange = (topic) => {
    // setCurrTopic(topic)
  };

  const handleCheckout = (price) => {
    // if (course?.package?.isFree) {
    onCreatePayment({
      variables: {
        nextPaymentDate: moment().add(1, "M"),
        // packageId: course?.package?.guid,
        courseId: course?.guid,
        userId: user?.guid,
        amount: price,
      },
      onCompleted: () => {
        Notify.success("Enrollment Success!");
        setCoursePaid(true);
        // window.location.reload(); 
      },
      onError: (err) => {
        console.log(err);
        Notify.success("Failed to enroll!");
      },
      refetchQueries: [
        {
          query: GET_USER_PAID_PACKAGES,
          variables: { userIdGuid: user?.guid },
        },
      ],
    });
    // } else {
    //   navigate(PATH_DASHBOARD.courses.checkout(course?.package?.guid));
    // }
  };

  const handlePaystackSuccess = (data) => {
    handleCheckout(course?.price);
    Notify.success("Thank you for your payment");
  };

  const handlePaystackClose = (data) => {
    Notify.failure("Are you sure you want to close?");
  };

  useEffect(() => {
    document.body.className = "bg-light";
  });

  return (
    <Fragment>
      <section className="py-lg-5 py-5">
        <Container>
          {/*  Video section  */}
          <Row>
            <Col lg={12} md={12} sm={12} className="mb-5">
              <div
                className="rounded-3 position-relative w-100 d-block overflow-hidden p-0"
              // style={{ height: "600px" }}
              >
                {course?.introVideo.length !== 0 ? (
                  <VideoPlayer
                    title={course?.title || "Test"}
                    video={
                      course?.introVideo[0]
                    }
                  />
                ) : (
                  <Image
                    src={course?.introImage[0]}
                    id="img-uploaded"
                    fluid
                    className=""
                    style={{ objectFit: 'fill' }}
                    alt=""
                  />
                )}
              </div>
            </Col>
          </Row>
          {/*  Content  */}
          <Row>
            <Col xl={8} lg={12} md={12} sm={12} className="mb-4 mb-xl-0">
              {/*  Card  */}
              <Tab.Container defaultActiveKey="description">
                <Card className="mb-5">
                  {/*  Card body  */}
                  <Card.Body>
                    <div className="d-flex justify-content-between align-items-center">
                      <h1 className="fw-semi-bold mb-2">{course?.title}</h1>
                      {/* {isCoursePaid && (
                        <Button
                          as={Link}
                          to={PATH_DASHBOARD.courses.resume(course?.guid)}
                          variant="outline-primary"
                        >
                          View Modules
                        </Button>
                      )} */}
                    </div>
                    {isCoursePaid &&

                      <div className="me-6 my-3">
                        <ProgressBar
                          variant="info"
                          className="mb-2 progress"
                          now={courseProgress?.courseProgress || 0}
                          style={{ height: "6px" }}
                        />
                        <small>{courseProgress?.courseProgress ? parseFloat(courseProgress?.courseProgress).toFixed(2) : 0}% Completed</small>
                      </div>

                    }
                    <div className="d-flex mb-5">
                      <span>
                        <span className="text-warning">
                          <Ratings rating={4.5} />
                        </span>
                        <span className="fw-medium">(140)</span>
                      </span>

                      <span className="ms-4 d-none d-md-block">
                        <LevelIcon level={course?.dificultyLevel} />
                        <span>{course?.dificultyLevel}</span>
                      </span>
                      {/* <span className="ms-4 d-none d-md-block">
                        <Icon path={mdiAccountMultipleOutline} size={0.7} />{" "}
                        <span>Enrolled</span>
                      </span> */}
                    </div>
                    <div className="d-flex justify-content-between">
                      <div className="d-flex align-items-center">
                        <Avatar
                          size="md"
                          className="rounded-circle"
                          type={course?.creator?.image ? "image" : "initial"}
                          src={course?.creator?.image}
                          // status={course?.creator?.status.toLowerCase()}
                          alt={course?.creator?.firstName}
                          name={`${course?.creator?.firstName} ${course?.creator?.lastName}`}
                        />
                        <div className="ms-2 lh-1">
                          <h4 className="mb-1">{`${course?.creator?.firstName} ${course?.creator?.lastName}`}</h4>
                          <p className="fs-6 mb-0">{course?.creator?.email}</p>
                        </div>
                      </div>
                      <div>
                        {/* <Link
                          to="#"
                          className="btn btn-outline-secondary btn-sm"
                        >
                          Follow
                        </Link> */}
                      </div>
                    </div>
                  </Card.Body>
                  {/*  Nav tabs  */}
                  <Nav className="nav-lt-tab">
                    {/* {["Description", "Reviews", "Transcript", "FAQ"].map( */}
                    {TABS.map((item, index) => (
                      <Nav.Item key={index}>
                        <Nav.Link
                          href={`#${item.toLowerCase()}`}
                          eventKey={item.toLowerCase()}
                          className="mb-sm-3 mb-md-0"
                        >
                          {item}
                        </Nav.Link>
                      </Nav.Item>
                    ))}
                  </Nav>
                </Card>
                {/*  Card  */}
                <Card className="rounded-3">
                  {/*  Card body  */}
                  <Card.Body className="p-0">
                    <Tab.Content>
                      <Tab.Pane eventKey="description" className="pb-4 p-4">
                        {/* Description Tab */}
                        <DescriptionTab course={course} isCoursePaid={isCoursePaid} user={user}/>
                      </Tab.Pane>
                      <Tab.Pane eventKey="reviews" className="pb-4 p-4">
                        {/* Reviews Tab */}
                        <ReviewsTab
                          reviews={reviews}
                          course={course}
                          user={user}
                          isAuthenticated={isAuthenticated}
                          updateReviews={(review) =>
                            setReviews([...reviews, review])
                          }
                        />
                      </Tab.Pane>
                      <Tab.Pane eventKey="transcript" className="pb-4 p-4">
                        {/* Transcript Tab */}
                        <TranscriptTab />
                      </Tab.Pane>
                      <Tab.Pane eventKey="faq" className="pb-4 p-4">
                        {/* FAQ Tab */}
                        <FAQTab />
                      </Tab.Pane>
                      <Tab.Pane eventKey="chat" className="pb-4 p-4">
                        {isAuthenticated &&
                          <ChatTab chatInfo={chatInfo} />
                        }
                      </Tab.Pane>
                    </Tab.Content>
                  </Card.Body>
                </Card>
              </Tab.Container>
            </Col>
            <Col xl={4} lg={12} md={12} sm={12}>
              <Card>
                {isCoursePaid ? (
                  <GKAccordionProgress
                    accordionItems={modules}
                    onSelected={handleTopicChange}
                    courseProgress={courseProgress}
                    isReview={isReview}
                  />
                ) : (
                  <Row className="p-4 gap-2">
                    <h3 className="text-gray-500">{course?.title}</h3>
                    <h4>
                      {course?.price === 0 ? (
                        "FREE"
                      ) : (
                        <>KES {numberWithCommas(course?.price)}</>
                      )}
                    </h4>

                    {!isAuthenticated ? (
                      <p>Login to view Course Info</p>
                    ) : (
                      <>
                        {course?.price === 0 ? (
                          <Button
                            variant="outline-primary"
                            onClick={() => handleCheckout(0)}
                          >
                            {isCreateFreeLoader ? "Loading..." : "Get Started"}
                          </Button>
                        ) : (
                          <PaystackButton
                            className="btn btn-outline-primary"
                            email={user?.email}
                            amount={course?.price * 100}
                            metadata={{
                              name: `${user?.firstName} ${user?.lastName}`,
                              phone: user?.phone,
                            }}
                            publicKey={process.env.REACT_APP_PAYSTACK_KEY}
                            currency="KES"
                            text="Get Access"
                            channels={["card", "bank", "mobile_money"]}
                            onSuccess={handlePaystackSuccess}
                            onClose={handlePaystackClose}
                          />
                        )}
                      </>
                    )}
                  </Row>
                )}
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
};

export default CourseSingle2;
